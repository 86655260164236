/* eslint-disable eol-last */
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import axios from 'axios'
import store from '@/store'

export const useUser = () => {}

export const useUserUi = () => {
  const usersMenu = payload => store.dispatch('app-user/usersMenu', payload)

  const promocodesMenu = payload => store.dispatch('app-user/userPromocodes', payload)

  const getStoreTimeslots = payload => store.dispatch('app-user/getStoreTimeslots', payload)

  const saveStoreTimeslots = payload => store.dispatch('app-user/saveStoreTimeslots', payload)

  const bookUserTableOnline = payload => store.dispatch('app-user/bookUserTableOnline', payload)

  const userProfile = () => store.dispatch('app-user/getUserProfile')

  const userCountry = () => store.dispatch('app-user/getUserCountry')

  const userCities = () => store.dispatch('app-user/getUserCities')

  const userAddressInfo = () => store.dispatch('app-user/getUserAddressInfo')

  const userNotifications = () => store.dispatch('app-user/userNotifications')

  // eslint-disable-next-line arrow-body-style
  const userStoreAutoComplete = searchCriteria => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${jwtDefaultConfig.apiEndpoint}/user/store/search/general?search=${searchCriteria.searchInput}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  const updateUserProfileInfo = formData => store.dispatch('app-user/updateUserProfileInfo', formData)

  const getDataTable = url => store.dispatch('app-user/getGoodTableData', { url })

  const userStoreList = query => store.dispatch('app-user/getUserStoreList', query)

  const userStoreDetail = payload => store.dispatch('app-user/getUserStoreDetail', payload)

  const storeCuisines = payload => store.dispatch('app-user/storeCuisines', payload)

  const userPromocode = payload => store.dispatch('app-user/getPromocodeList', payload)

  const userStoreOrder = payload => store.dispatch('app-ecommerce/storeOrderDetail', payload)

  const userServiceSubCategory = payload => store.dispatch('app-user/getServiceSubCategory', payload)

  const userServiceProviders = payload => store.dispatch('app-user/getServiceProvidersList', payload)

  const userProviderServiceDetail = payload => store.dispatch('app-user/getProviderServiceDetail', payload)

  const userServiceProviderUserAddress = payload => store.dispatch('app-user/getServiceProviderUserAddress', payload)

  const userServiceTimeslots = payload => store.dispatch('app-user/getServiceTimeslots', payload)

  const bookServiceTimeslots = payload => store.dispatch('app-user/bookServiceTimeslots', payload)

  const userServicePromocode = () => store.dispatch('app-user/getServicePromocodeList')

  const userServiceRequest = payload => store.dispatch('app-ecommerce/getServiceRequest', payload)

  const createServiceDispute = payload => store.dispatch('app-user/createServiceDispute', payload)

  const createOrderDispute = payload => store.dispatch('app-user/createOrderDispute', payload)

  const userDisputeStatus = payload => store.dispatch('app-user/getDisputeStatus', payload)

  const userOrderDisputeStatus = payload => store.dispatch('app-user/getOrderDisputeStatus', payload)

  const userTripServiceHistory = payload => store.dispatch('app-user/getTripServiceHistory', payload)

  const userCancelServiceRequest = payload => store.dispatch('app-user/cancelServiceRequest', payload)

  const userCancelOrderRequest = payload => store.dispatch('app-user/cancelOrderRequest', payload)

  const userChangePassword = payload => store.dispatch('app-user/changeUserPassword', payload)

  const userAddNewAddress = payload => store.dispatch('app-user/addNewUserAddress', payload)

  const userDeleteAddress = payload => store.dispatch('app-user/deleteUserAddress', payload)

  const userOnGoingServices = () => store.dispatch('app-user/userOnGoingServices')

  const userWalletList = () => store.dispatch('app-user/getWalletList')

  const userApproveService = payload => store.dispatch('app-user/userApproveService', payload)

  const userCardList = () => store.dispatch('app-user/getUsersCard')

  const userAddMoney = payload => store.dispatch('app-user/addMoney', payload)

  const sendMoneyToUser = payload => store.dispatch('app-user/sendMoneyToUser', payload)

  const userDeletePaymentMethod = payload => store.dispatch('app-user/userDeletePaymentMethod', payload)

  const addUserCard = payload => store.dispatch('app-user/addUserCard', payload)

  const userTripHistory = payload => store.dispatch('app-user/userTripHistory', payload)

  const updateUserCity = payload => store.dispatch('app-user/updateUserCity', payload)

  const storeItems = payload => store.dispatch('app-user/storeItems', payload)

  const storePhotos = payload => store.dispatch('app-user/storePhotos', payload)

  const storeReview = payload => store.dispatch('app-user/storeReview', payload)

  const makeStripePayment = payload => store.dispatch('app-user/makeStripePayment', payload)
  
  const completeStripePayment = payload => store.dispatch('app-user/completeStripePayment', payload)

  const makeFlutterwavePayment = payload => store.dispatch('app-user/makeFlutterwavePayment', payload)
  
  const completeFlutterwavePayment = payload => store.dispatch('app-user/completeFlutterwavePayment', payload)

  const razorPayPayment = payload => store.dispatch('app-user/razorPayPayment', payload)

  const razorPayPaymentService = payload => store.dispatch('app-user/razorPayPaymentService', payload)

  const phonePePayment = payload => store.dispatch('app-user/phonePePayment', payload)

  const paypalPayment = payload => store.dispatch('app-user/paypalPayment', payload)

  const phonePePaymentService = payload => store.dispatch('app-user/phonePePaymentService', payload)

  const paypalPaymentService = payload => store.dispatch('app-user/paypalPaymentService', payload)

  const getAddOnsInfo = payload => store.dispatch('app-user/getAddOnsInfo', payload)

  const getProviderTimings = payload => store.dispatch('app-user/getProviderTimings', payload)

  const addChats = payload => store.dispatch('app-user/addChats', payload)

  const getChatsForUser = payload => store.dispatch('app-user/getChatsForUser', payload)

  const getTicketSubCategory = payload => store.dispatch('app-user/getTicketSubCategory', payload)

  const createTicket = payload => store.dispatch('app-user/createTicket', payload)

  const getTicketInfo = payload => store.dispatch('app-user/getTicketInfo', payload)

  const updateTicket = payload => store.dispatch('app-user/updateTicket', payload)

  const addTicketComment = payload => store.dispatch('app-user/addTicketComment', payload)

  const phonePeSuccess = payload => store.dispatch('app-user/phonePeSuccess', payload)

  const paypalSuccess = payload => store.dispatch('app-user/paypalSuccess', payload)

  const paypalServiceSuccess = payload => store.dispatch('app-user/paypalServiceSuccess', payload)

  const getOrderDetailById = payload => store.dispatch('app-user/getOrderDetailById', payload)

  const storeRating = payload => store.dispatch('app-user/storeRating', payload)

  const storeOrderRating = payload => store.dispatch('app-user/storeOrderRating', payload)

  const providerRating = payload => store.dispatch('app-user/providerRating', payload)

  const postServiceReview = payload => store.dispatch('app-user/postServiceReview', payload)

  const postDeliveryOrderReview = payload => store.dispatch('app-user/postDeliveryOrderReview', payload)

  const postOrderReview = payload => store.dispatch('app-user/postOrderReview', payload)

  const retryPayment = payload => store.dispatch('app-user/retryPayment', payload)

  const getUserChat = payload => store.dispatch('app-user/getUserChat', payload)

  const addUserChat = payload => store.dispatch('app-user/addUserChat', payload)

  const getUserChatOverview = payload => store.dispatch('app-user/getUserChatOverview', payload)

  const searchUser = payload => store.dispatch('app-user/searchUser', payload)

  const knowledgeBaseCategory = () => store.dispatch('app-user/knowledgeBaseCategory')

  const knowledgeBaseList = payload => store.dispatch('app-user/knowledgeBaseList', payload)

  const knowledgeBaseListById = payload => store.dispatch('app-user/knowledgeBaseListById', payload)

  const changeUserStatus = payload => store.dispatch('app-user/changeUserStatus', payload)

  const ordersChat = () => store.dispatch('app-user/ordersChat')

  const servicesChat = () => store.dispatch('app-user/servicesChat')

  const getBlogCommentById = payload => store.dispatch('app-user/getBlogCommentById', payload)

  const listBlogComments = payload => store.dispatch('app-user/listBlogComments', payload)

  const addBlogComments = payload => store.dispatch('app-user/addBlogComments', payload)

  const updateBlogComments = payload => store.dispatch('app-user/updateBlogComments', payload)

  const deleteBlogComments = payload => store.dispatch('app-user/deleteBlogComments', payload)

  const fetchBlogComments = payload => store.dispatch('app-user/fetchBlogComments', payload)

  const updateChatStatus = payload => store.dispatch('app-user/updateChatStatus', payload)

  const knowledgeBaseLatest = () => store.dispatch('app-user/knowledgeBaseLatest')

  const getAllBlogs = () => store.dispatch('app-user/getAllBlogs')

  const getLatestBlogList = () => store.dispatch('app-user/getLatestBlogList')

  const knowledgeBaseQuestionById = payload => store.dispatch('app-user/knowledgeBaseQuestionById', payload)

  const blogListById = payload => store.dispatch('app-user/blogListById', payload)

  const getFAQListAll = () => store.dispatch('app-user/getFAQListAll')

  const commonShareInfo = payload => store.dispatch('app-user/commonShareInfo', payload)

  const termsAndConditionDetail = payload => store.dispatch('app-user/termsAndConditionDetail', payload)

  const rescheduleService = payload => store.dispatch('app-user/rescheduleService', payload)

  const countryList = () => store.dispatch('app-user/countryList')

  const cityByCountry = payload => store.dispatch('app-user/cityByCountry', payload)

  const updateUserAddress = payload => store.dispatch('app-user/updateUserAddress', payload)

  const shareBlog = payload => store.dispatch('app-user/shareBlog', payload)

  const likeBlog = payload => store.dispatch('app-user/likeBlog', payload)

  const menuCountryList = () => store.dispatch('app-user/menuCountryList')

  const deleteUserAccount = () => store.dispatch('app-user/deleteUserAccount')

  const currentCart = () => store.dispatch('app-user/currentCart')

  const removeStoreCart = () => store.dispatch('app-user/removeStoreCart')

  const removeProviderCart = () => store.dispatch('app-user/removeProviderCart')

  const getStateByCountry = payload => store.dispatch('app-user/getStateByCountry', payload)

  const getCityByStateCountry = payload => store.dispatch('app-user/getCityByStateCountry', payload)

  return {
    getCityByStateCountry,
    getStateByCountry,
    removeProviderCart,
    removeStoreCart,
    currentCart,
    likeBlog,
    shareBlog,
    updateUserAddress,
    deleteUserAccount,
    cityByCountry,
    menuCountryList,
    countryList,
    rescheduleService,
    termsAndConditionDetail,
    commonShareInfo,
    getFAQListAll,
    blogListById,
    getLatestBlogList,
    getAllBlogs,
    knowledgeBaseQuestionById,
    knowledgeBaseLatest,
    updateChatStatus,
    fetchBlogComments,
    deleteBlogComments,
    updateBlogComments,
    addBlogComments,
    listBlogComments,
    getBlogCommentById,
    servicesChat,
    ordersChat,
    changeUserStatus,
    knowledgeBaseListById,
    knowledgeBaseList,
    knowledgeBaseCategory,
    searchUser,
    getUserChatOverview,
    addUserChat,
    getUserChat,
    postDeliveryOrderReview,
    paypalServiceSuccess,
    retryPayment,
    postOrderReview,
    postServiceReview,
    storeRating,
    storeOrderRating,
    providerRating,
    getOrderDetailById,
    phonePePaymentService,
    paypalPaymentService,
    phonePeSuccess,
    paypalSuccess,
    paypalPayment,
    addTicketComment,
    updateTicket,
    getTicketInfo,
    createTicket,
    getTicketSubCategory,
    getChatsForUser,
    addChats,
    getProviderTimings,
    userCancelOrderRequest,
    getAddOnsInfo,
    phonePePayment,
    razorPayPayment,
    razorPayPaymentService,
    makeStripePayment,
    storeCuisines,
    storeReview,
    storePhotos,
    storeItems,
    getStoreTimeslots,
    saveStoreTimeslots,
    bookUserTableOnline,
    userCities,
    updateUserCity,
    userTripHistory,
    addUserCard,
    userDeletePaymentMethod,
    usersMenu,
    userProfile,
    userCountry,
    userAddressInfo,
    userStoreAutoComplete,
    updateUserProfileInfo,
    getDataTable,
    userStoreList,
    userStoreDetail,
    userPromocode,
    userStoreOrder,
    userServiceSubCategory,
    userServiceProviders,
    userProviderServiceDetail,
    userServiceProviderUserAddress,
    userServiceTimeslots,
    bookServiceTimeslots,
    userServicePromocode,
    userServiceRequest,
    createServiceDispute,
    createOrderDispute,
    userDisputeStatus,
    userTripServiceHistory,
    userCancelServiceRequest,
    userChangePassword,
    userAddNewAddress,
    userDeleteAddress,
    userOrderDisputeStatus,
    promocodesMenu,
    userOnGoingServices,
    userWalletList,
    userApproveService,
    userCardList,
    userAddMoney,
    userNotifications,
    sendMoneyToUser,
    completeStripePayment,
    makeFlutterwavePayment,
    completeFlutterwavePayment,
  }
}
